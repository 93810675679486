/* Seção Banner ------------------------- */
.banner {
  width: 100%;
  height: 600px;
  padding: 0px 0px 0px 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: black;
}

.text-black {
  width: 40%;
  padding: 40px;
  background-color: black;
  color: white;
}

.text-black p {
  font-weight: 400;
  font-size: 1.5rem;
}

.text-black h2 {
  font-weight: 800;
  font-size: 3.7rem;
}

.text-black h3 {
  font-weight: 400;
  font-size: 1.8rem;
}

.image-right {
  width: 60%;
  height: 100%;
  background-image: url('../../assets/images/banner.webp');
  background-size: cover;
  background-position: center;
}

/* Seção Expert ------------------------- */
.image-expert {
  width: 60%;
  height: 100%;
  background-image: url('../../assets/images/expert.webp');
  background-size: cover;
  background-position: center;
}

/* Seção Método ------------------------- */
.metodo {
  width: 100%;
  height: 400px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #eee;
}

.metodo p {
  font-weight: 400;
  font-size: 1.5rem;
}

.metodo h2 {
  font-weight: 800;
  font-size: 3.7rem;
  text-align: center;
  color: var(--primary-color);
  padding-bottom: 15px;
}

.metodo-cards {
  display: flex;
  align-items: center;
  justify-content: center;
}

.metodo-box {
  width: 190px;
  height: 250px;
  margin: 10px;
  padding: 20px;
  border-radius: 20px;
  background-color: white;
  box-shadow: 2px 2px 3px #a9a9a9;
}

.metodo-box h3 {
  font-weight: 800;
  font-size: 1.8rem;
  padding-bottom: 10px;
}

/* Seção Certificações ------------------------- */
.bg-primary-color {
  width: 100%;
  height: auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: var(--primary-color);
}

.bg-primary-color p {
  font-weight: 400;
  font-size: 1.5rem;
}

.bg-primary-color h2 {
  font-weight: 800;
  font-size: 3.7rem;
  text-align: center;
  padding-bottom: 15px;
}

.bg-primary-color h3 {
  padding-bottom: 15px;
}

.bg-primary-color-cards {
  display: flex;
  align-items: center;
  justify-content: center;
}

.bg-primary-color-box {
  width: 100px;
  height: 100px;
  margin: 10px;
  padding: 20px;
  border-radius: 100px;
  background-color: white;
  box-shadow: 2px 2px 3px #a9a9a9;
}

.bg-primary-color-box h3 {
  font-weight: 800;
  font-size: 1.8rem;
  padding-bottom: 10px;
}

.bg-primary-color-box p {
  font-weight: 400;
  font-size: 1.5rem;
  color: white;
  padding-top: 10px;
  padding-bottom: 10px;
}

.bg-primary-color a {
  color: white;
  text-decoration: none;
}

.bg-prim-icons a {
  padding: 10px;
  font-size: 1.8rem;
}

.gads {
  background-image: url('../../assets/images/gads.webp');
  background-size: cover;
  background-position: center;
}

.analytics {
  background-image: url('../../assets/images/analytics.webp');
  background-size: cover;
  background-position: center;
}
.metaads {
  background-image: url('../../assets/images/metads-breve.webp');
  background-size: cover;
  background-position: center;
}

/* Botão CTA ------------------------- */
.button-action {
  width: 200px;
  height: 40px;
  background-color: var(--primary-color);
  border: none;
  border-radius: 10px;
  font-size: 1.5rem;
  font-weight: 200;
  color: white;
  cursor: pointer;
}

.button-action:hover {
  background-color: #9A00FF;
}

/* Ajuste para telas 1000px ------------------------- */
@media (max-width: 1000px) {
  .image-right {
    background-image: url('../../assets/images/banner-small.webp');
  }

  .metodo {
    height: auto;
  }

  .metodo-cards {
    flex-direction: column;
  }

  .metodo-box {
    width: 100%;
    height: auto;
  }
}

/* Ajuste para telas 700px ------------------------- */
@media (max-width: 700px) {
  .banner {
    padding: 0px;
    flex-direction: column;
  }

  .image-right {
    width: 100%;
    height: 500px;
  }

  .text-black {
    padding: 30px;
    width: auto;
    font-size: 1.5rem;
  }

  .text-black h2 {
    font-size: 2.7rem;
  }
  
  .text-black h3 {
    font-size: 1.5rem;
  }

  .metodo h2 {
    font-size: 2.7rem;
  }

  .bg-primary-color h2 {
    font-size: 2.7rem;
  }
}