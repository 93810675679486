.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  align-content: center;
  height: var(--footer-height);
  font-size: 1.2rem;
  background: var(--primary-color);
  color: white;
}

.footer a {
  color: white;
  text-decoration: none;
}
