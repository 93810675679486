.wap-link a {
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  background: #25d366;
  width: 6rem;
  height: 6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: var(--primary-color);
}

@media (max-width: 700px) {
  .wap-link a {
    bottom: 2rem;
    right: 2rem;
  }
}