body {
  margin: 0;
  padding: 0;
  color: var(--font-color);
}

/* 
navbar 
 |_ logo
 |_ menu-icon
 |_ menu-container
    |_ menu-list
*/

.navbar {
  width: 100%;
  height: var(--menu-height);
  display: flex;
  position: fixed;
  margin: 0 auto;
  padding: 0 3rem 0 3rem;
  z-index: 1;
  align-items: center;
  justify-content: space-between;
  background-color: var(--primary-color);
  box-shadow: 1px 1px 10px 1px black;
}

.nav-padding {
  width: 100%;
  height: var(--menu-height);
}

.logo img {
 width: 110px;
 height: auto;
}

.menu-icon {
  display: none;
}
.menu-icon img {
  width: 40px;
  height: 40px;
}

.menu-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  list-style-type: none;
}

.button-action-menu {
  width: 200px;
  height: 40px;
  background-color: #49007A;
  border: 1px solid white ;
  border-radius: 10px;
  font-size: 1.5rem;
  font-weight: 200;
  color: white;
  cursor: pointer;
}

.button-action-menu:hover {
  background-color: #9A00FF;
}


@media (max-width: 700px) {
  .menu-icon {
    display: block;
    cursor: pointer;
  }

  .menu-container {
    position: absolute;
    width: 0px;
    height: calc(100vh - var(--menu-height));
    top: var(--menu-height);
    right: 0;
    background-color: var(--menu-color);
    transition: all 0.3s ease-in;
    overflow: hidden;
  }

  .menu-container.active {
    width: var(--lateral-menu);
  }

  .menu-list {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
}